* {
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Ionicons-Pre"; 
  src: font("ionicons.eot", $base: $base-url); 
  src: font("ionicons.eot#iefix", $base: $base-url) format("embedded-opentype"), 
    font("ionicons.woff", $base: $base-url) format("woff"), 
    font("ionicons.ttf", $base: $base-url) format("truetype"), 
    font("ionicons.svg#Ionicons", $base: $base-url) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Calibre"; 
  src: font("CalibreLight.eot", $base: $base-url); 
  src: font("CalibreLight.eot?#iefix", $base: $base-url) format("embedded-opentype"), 
    font("CalibreLight.woff2", $base: $base-url) format("woff2"), 
    font("CalibreLight.woff", $base: $base-url) format("woff"), 
    font("CalibreLight.ttf", $base: $base-url) format("truetype"), 
    font("CalibreLight.svg#CalibreLight", $base: $base-url) format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Calibre"; 
  src: font("CalibreLightItalic.eot", $base: $base-url); 
  src: font("CalibreLightItalic.eot?#iefix", $base: $base-url) format("embedded-opentype"), 
    font("CalibreLightItalic.woff2", $base: $base-url) format("woff2"), 
    font("CalibreLightItalic.woff", $base: $base-url) format("woff"), 
    font("CalibreLightItalic.ttf", $base: $base-url) format("truetype"), 
    font("CalibreLightItalic.svg#CalibreLightItalic", $base: $base-url) format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "GT America v1 Compressed"; 
  src: font("GTAmericav1-CompressedMedium.eot", $base: $base-url); 
  src: font("GTAmericav1-CompressedMedium.eot?#iefix", $base: $base-url) format("embedded-opentype"), 
    font("GTAmericav1-CompressedMedium.woff2", $base: $base-url) format("woff2"), 
    font("GTAmericav1-CompressedMedium.woff", $base: $base-url) format("woff"), 
    font("GTAmericav1-CompressedMedium.ttf", $base: $base-url) format("truetype"), 
    font("GTAmericav1-CompressedMedium.svg#GTAmericav1-CompressedMedium", $base: $base-url) format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GT America v1 Compressed"; 
  src: font("GTAmericav1-CompressedLight.eot", $base: $base-url); 
  src: font("GTAmericav1-CompressedLight.eot?#iefix", $base: $base-url) format("embedded-opentype"), 
    font("GTAmericav1-CompressedLight.woff2", $base: $base-url) format("woff2"), 
    font("GTAmericav1-CompressedLight.woff", $base: $base-url) format("woff"), 
    font("GTAmericav1-CompressedLight.ttf", $base: $base-url) format("truetype"), 
    font("GTAmericav1-CompressedLight.svg#GTAmericav1-CompressedLight", $base: $base-url) format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "GT America v1 Compressed"; 
  src: font("GTAmericav1-CompressedBold.eot", $base: $base-url); 
  src: font("GTAmericav1-CompressedBold.eot?#iefix", $base: $base-url) format("embedded-opentype"), 
    font("GTAmericav1-CompressedBold.woff2", $base: $base-url) format("woff2"), 
    font("GTAmericav1-CompressedBold.woff", $base: $base-url) format("woff"), 
    font("GTAmericav1-CompressedBold.ttf", $base: $base-url) format("truetype"), 
    font("GTAmericav1-CompressedBold.svg#GTAmericav1-CompressedBold", $base: $base-url) format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "slick"; 
  src: font("slick.eot", $base: $base-url); 
  src: font("slick.eot?#iefix", $base: $base-url) format("embedded-opentype"), 
    font("slick.woff", $base: $base-url) format("woff"), 
    font("slick.ttf", $base: $base-url) format("truetype"), 
    font("slick.svg#slick", $base: $base-url) format("svg");
  font-weight: normal;
  font-style: normal;
}
