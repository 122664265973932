//////////////////////////////////////////////////////////////////
// Bourbon Neat Grid
//////////////////////////////////////////////////////////////////

// Bourbon Visual Grid
$visual-grid:               false !default;
$visual-grid-color:         magenta !default;
$visual-grid-opacity:       0.05 !default;

// Grid Settings
$max-width:                 1200px !default;
$column:                    60px;
$gutter:                    120px;
$grid-columns:              12;

// ------------------------------------------------------------
// Set Bourbon's flex-grid variables to match what Neat is doing
// http://bourbon.io/docs/#flex-grid
// http://www.joshfry.me/blog/2013/05/13/omega-reset-for-bourbon-neat
// ------------------------------------------------------------
$fg-column: $column;
$fg-gutter: $gutter;