.page-block {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;

  &__inner {
    width: 100%;
  }

  &.is-left {
    left: 0;
    right: auto;
  }

  &.is-right {
    left: 50%;
    right: 0;
  }

  // Scroll
  &.has-scroll {
    overflow-y: auto;
    overflow-x: none;
  }

  // Padding
  &.has-padding {
    padding: 160px 52px 52px;
  }
}

body.page-template-page-info {
  overflow: hidden;
}

@include media($mobile) {

  body.page-template-page-info {
    overflow: auto;
    .layout__content {
      margin-bottom: 0;
    }
  }

  section#lookbook {
    display: flex;
    flex-wrap: wrap;

    .page-block {
      width: 100%;
      position: relative;
      overflow-x: auto;
      position: relative;

      &.is-left {
        order: 2;
        left: 0;
        right: 0;
        &.has-padding {
          padding: 60px 40px;
        }
      }
      &.is-right {
        order: 1;
        left: 0;
        right: 0;
        &.has-padding {
          padding: 60px 0;
        }
      }
    }
  }
}
