.lookbook-preview {

  &__inner {
    @include position(relative);
  }

  &__image {
    display: block;
    width: 100%;
    position: relative;
    &__inner {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0px; bottom: 0px; right: 0px; left: 0px;
    }
    &:after {
      padding-top: 100%;
      display: block;
      content: '';
    }
  }
}