.spacer {
  border: none;
  background-color: transparent;
  @include size(100% 1px);
  &.is-xxlarge {
    @include margin(120px null);
  }
  &.is-xlarge {
    @include margin(60px null);
  }
  &.is-large {
    @include margin(40px null);
  }
  &.is-medium {
    @include margin(20px null);
  }
  &.is-small {
    @include margin(10px null);
  }
  &.is-line {
    background-color: #ccc;
  }
}