.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

.container {
  @include outer-container();
  padding-right: 40px;
  padding-left: 40px;
  &.is-full {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  &.is-fluid {
    max-width: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }
  &.is-large {
    max-width: 1400px;
    margin: 0 auto;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.hide-text {
  overflow: hidden;
  padding: 0;
  text-indent: 101%;
  white-space: nowrap;
}

.visually-hidden { 
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.align-center {
  margin: 0 auto;
}
