html {
  box-sizing: border-box;
}

html,
body {
  background-color: $page-background;
  font-family: $font-body;
}

*, *::before, *::after {
  box-sizing: inherit;
} 