$add-to-cart-size: 130px;

.product {
  &__inner {
    @include outer-container();
  }

  // Layout Right
  &__gallery {
    background-color: #f2f2f2;
    @include position(fixed, 0 50% 0 0);
  }

  &__mobile__gallery {
    display: none;
    position: relative;
    height: 350px;
  }

  // Layout Left
  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 40px;
    @include position(fixed, 0 0 0 50%);
  }

  &__header,
  &__body,
  &__footer {
    width: 100%;
    height: auto;
  }
  &__header {
    align-self: flex-start;
  }
  &__footer {
    align-self: flex-end;
    display: flex;
    width: 100%;
    flex-direction: row;
    .price {
      flex-grow: 1;
      font-weight: 300;
    }
  }

  &__tabs {
    .tabs__content-item {
      padding: 10px 0;
      display: none;

      &.active {
        display: block;
        min-height: 60px;
      }
    }
  }

  &__content {
    padding: 40px;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
  }

  &__body {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__out-of-stock {
    font-family: 'GT America v1 Compressed', sans-serif;
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    text-decoration: none;
    letter-spacing: 0;
    text-transform: inherit;
    color: $color-red;
  }

  &__select {
    margin-bottom: 30px;
    margin-top: 30px;
    line-height: 32px;
    font-size: 15px;
    letter-spacing: .05em;
    font-weight: 400;
    text-transform: uppercase;

    &.hidden {
      display: none!important;
    }

    label {
      display: inline-block;
      margin-right: 30px;

      span.lbl {
        font-family: $default-font-family;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-transform: inherit;
        color: black;
        margin-right: 10px;
      }
    }
  }

  &__header {
    margin: 0 0 40px 0;

    .title {
      @include secondary-font();
      font-size: 50px;
      line-height: 70px;
    }
    .flex-right {
      a {
        font-family: 'GT America v1 Compressed', sans-serif;
        font-size: 45px;
        font-weight: bold;
        line-height: 40px;
        position: relative;
        display: inline-block;
        margin-right: 10px;
        padding: 0 0 0 0;
        letter-spacing: 0;
        text-transform: uppercase;
      }
    }
  }

  &__actions {
    display: flex;
    flex-wrap: nowrap;
    text-transform: uppercase;

    &__price {
      span.price {
        font-family: 'GT America v1 Compressed', sans-serif;
        font-size: 30px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0;
        text-transform: inherit;
      }
      margin-right: 30px;
    }

    &__quantity {
      display: inline-block;
      margin-right: 10px;

      label {
        color: #999;
        font-family: 'GT America v1 Compressed', sans-serif;
        font-size: 30px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0;
        text-transform: inherit;
        margin-right: 10px;
      }
      input.qty {
        outline: none;
        border: none;
        height: 30px;
        background-color: transparent;
        font-family: 'GT America v1 Compressed', sans-serif;
        font-size: 30px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0;
        text-transform: inherit;
        width: 40px;
        display: inline-block;
      }
    }

    &__add {
      font-family: 'GT America v1 Compressed', sans-serif;
      font-size: 30px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0;
      text-transform: inherit;
      text-decoration: none;

      a {
        &:hover {
          text-decoration: none;
          color: black;
        }
      }
    }
  }
}

#share {
  > div {
    display: inline-block;
    margin: 0 0 3px 0;
  }
}

section#product {

  .page-block.is-right {
    padding: 52px;

    .page-block__inner,
    .page-block__padding,
    .product__body {
      height: 100%;
    }
  }
}

body.single-product {
  overflow: hidden;
}

@include media($mobile) {
  body.single-product {
    background-color: #f2f2f2;

    .layout__content {
      margin-bottom: 0;
    }
  }

  section#product {
    display: flex;
    flex-wrap: wrap;

    .page-block {
      width: 100%;
      position: relative;
      overflow-x: auto;
      position: relative;

      &.is-left {
        order: 2;
        left: 0;
        right: 0;
        &.has-padding {
          padding: 60px 40px;
        }
      }
      &.is-right {
        order: 1;
        left: 0;
        right: 0;
        &.has-padding {
          padding: 60px 0;
        }
      }
    }

    .product__gallery {
      display: none;
    }

    .product__content {
      padding: 0;
      background-color: #f2f2f2;
    }

    .product__header,
    .product__tabs {
      padding: 40px;
      margin: 0;
    }

    .product__header {
      padding-top: 30px;
    }

    .product__body {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 80px;
      left: 0;
      display: block;
      overflow: auto;
      overflow-x: hidden;
      height: auto!important;
      padding: 80px 0 0 0;
      -webkit-overflow-scrolling: touch;
    }

    .product__mobile__gallery {
      display: block;
      ul.slick-dots {
        bottom: 20px;
      }
      .slick-dotted.slick-slider {
        margin-bottom: 0;
        height: 350px;
      }
    }

    .product__actions {
      line-height: 90px;
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      height: 80px;
      padding: 0 20px;
      background-color: white;
      box-shadow: 0 1px 40px rgba(0, 0, 0, .1);
      flex-wrap: nowrap;
    }

    .product__actions__price,
    .product__actions__quantity,
    .product__actions__add,
    .product__actions__out-of-stock {
      flex-grow: 1;
      white-space: nowrap;
      margin: 0 10px;
    }

    .product__actions {
      font-size: 22px;
      line-height: 80px;
      font-weight: 400;

      &__price {
        text-align: left;
        .price {
          line-height: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
      }
      &__quantity {
        label {
          line-height: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
        input {
          line-height: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
      }
      &__add {
        line-height: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-align: right;

        a {
          color: $color-red;
        }
      }
      &__out-of-stock {
        line-height: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-align: right;
        color: $color-red;
      }
    }
  }
}

// $add-to-cart-size: 130px;

// .product {

//   &__inner {
//     @include outer-container();
//   }

//   &__gallery {
//     @include position(fixed, 0 50% 0 0);
//     background-color: #f2f2f2;
//     @include media($mobile) {
//       display: none;
//     }
//   }

//   &__info {
//     @include position(fixed, 0 0 0 50%);
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .title {
//       margin-bottom: 40px;
//     }
//     @include media($mobile) {
//       @include position(relative, auto);
//       display: block;
//     }
//   }

//   &__header {
//     .title {
//       @include secondary-font();
//       font-size: 50px;
//       line-height: 70px;
//     }
//   }

//   &__action {
//     // @include position(fixed, null 0 0 50%);
//     // padding: 40px 40px 40px 80px;
//     .is-price {
//       display: inline-block;
//     }
//     .add-to-cart {
//       float: right;
//       @include position(relative);
//       display: inline-block;
//       margin-right: 10px;
//       font-family: $font-bold;
//       font-weight: bold;
//       font-size: $nav-font-size;
//       line-height: $nav-line-size;
//       letter-spacing: 0;
//       text-transform: uppercase;
//       color: black;
//       @include pad(0px 0px 0px 0px);
//       border: none;
//       background-color: transparent;
//       border: none;
//     }
//     @include media($mobile) {
//       @include position(relative, auto);
//     }
//   }

//   &__content {
//     margin: 80px;
//     width: 100%;
//     @include media($mobile) {
//       margin: 40px;
//     }
//     .text {
//       width: 80%;
//       @include media($mobile) {
//         width: 100%;
//       }
//     }
//   }

//   &__mobile__gallery {
//     display: none;
//     @include media($mobile) {
//       display: block;
//     }

//   }

// }
