$archive-gutter: 2px;

.archive-list {

  &__inner {
    @extend .container.is-full;
  }

  &__items {
    @include position(relative);
    margin: 0 -$archive-gutter;
  }

  &__item {
    @include display(inline-block);
    @include margin(0);
    padding: $archive-gutter;

    @include calc(width, "100% / 10");
    @include media($desktop) {
      @include calc(width, "100% / 8");
    }
    @include media($tablet) {
      @include calc(width, "100% / 6");
    }
    @include media($mobile) {
      @include calc(width, "100% / 3");
    }

    &.is-open {
      @include calc(width, "100% / 2.5");
      @include media($desktop) {
        @include calc(width, "100% / 2");
      }
      @include media($tablet) {
        @include calc(width, "100% / 1");
      }
      @include media($mobile) {
        @include calc(width, "100% / 1");
      }
    }
  }

}