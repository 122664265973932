.header {
  @include row();

  // Logo
  &__nav {
    @include span-columns(6);
  }

  // Navigation Bar
  &__user {
    @include span-columns(6);
    text-align: right;
  }

  &__info {
    position: fixed;
    bottom: 0px;
    left: 0px;
  }

  &--main {
    display: block;
    padding-top: 40px;
    padding-bottom: 20px;
    @include media($mobile) {
      display: none;
    }
  }
  &--mobile {
    display: none;
    height: 80px;
    line-height: 80px;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0px 1px 40px rgba(0,0,0,0.1);

    .container.is-fluid {
      display: flex;
    }

    @include media($mobile) {
      display: block;
    }
  }

  .mobile {
    line-height: 80px;

    &__logo {
      height: 80px;
      flex: 1;
      h1 {
        font-size: 40px;
        color: black;
        line-height: inherit;
        font-weight: 600;
        text-transform: uppercase;
        a {
          color: black;

          &:hover,
          &:active {
            color: $color-red;
            text-decoration: none;
          }
        }
      }
    }

    &__menu {
      @include transition(all 0.3s ease-in-out);
      overflow: hidden;
      height: 0px;
      background-color: rgba(255,255,255,0.9);
      padding: 0;
      > .container {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    &__cart {
      font-family: 'GT America v1 Compressed', sans-serif;
      margin: 0;
      padding: 0;
      font-size: 40px;
      font-weight: 600;
      line-height: inherit;
      text-transform: uppercase;
      color: black;
      a {
        color: black;
        text-decoration: none;

        &:hover,
        &:active {
          color: $color-red;
          text-decoration: none;
        }
      }
    }

    &__toggle {
      width: 40px;
      float: left;
      text-align: right;
      height: 80px;
      margin-right: 20px;
      .hamburger {
        padding: 0;
        height: 80px;
        line-height: 95px;
        outline: none;
      }
    }
  }
}

body.nav-open {
  .header {
    .mobile__menu {
      height: 100vh;
    }
  }
}
