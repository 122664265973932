.slick-loading {
  .slick-list {
    background-image: image("ajax-loader.gif", $base: $base-url);
  }
}

.slick-hp  {

  &-mask {
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 50vw;
    overflow: hidden;
    z-index: 3;
  }

  .slick-dots {
    position: absolute;
    top: 50%;
    right: 0px;
    left: auto;
    width: 30px;
    text-align: center;
    height: auto;
    bottom: auto;
    transform: translate(-50%, -50%);

    li {
      text-align: right;
      height: 20px;
      width: auto;
      margin: 0px;
      display: block;
      button {
        height: 100%;
        width: 100%;
        margin: 0px;
        &:before {
          height: 100%;
          width: 100%;
          font-size: 8px;
          opacity: 1;
          color: black;
        }
      }
      &.slick-active {
        button:before {
          color: red;
        }
      }
    }
  }
}

.slick-pdp {
  
  .slide-panel__item {
    background-color: #f2f2f2;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .slick-dots {
    position: absolute;
    top: 50%;
    left: 50px;
    right: auto;
    width: 30px;
    text-align: center;
    height: auto;
    bottom: auto;
    display: block;
    transform: translate(-50%, -50%);

    li {
      text-align: right;
      height: 20px;
      width: auto;
      margin: 0px;
      display: block;
      button {
        height: 100%;
        width: 100%;
        margin: 0px;
        &:before {
          height: 100%;
          width: 100%;
          font-size: 8px;
          opacity: 1;
          color: black;
        }
      }
      &.slick-active {
        button:before {
          color: red;
        }
      }
    }
  }

}