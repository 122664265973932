$vote-gutter: 20px;

.vote-list {

  &__inner {
    @extend .container;
    overflow: hidden;
  }

  &__items {
    @include position(relative);
    margin: 0 -$vote-gutter;
  }

  &__header {
    margin-bottom: 120px;
    .title {
      font-family: $font-light;
      font-weight: 300;
      font-size: 100px;
      line-height: 100px;
      letter-spacing: 0px;
      text-transform: inherit;
      @include media($desktop) {
        font-size: 85px;
        line-height: 85px;
      }
      @include media($tablet) {
        font-size: 75px;
        line-height: 75px;
      }
      @include media($mobile) {
        font-size: 50px;
        line-height: 50px;
      }
    }
    @include media($mobile) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  &__item {
    @include display(inline-block);
    @include margin(0);
    padding: $vote-gutter;

    @include calc(width, "100% / 3");
    @include media($desktop) {
      @include calc(width, "100% / 2");
    }
    @include media($tablet) {
      @include calc(width, "100% / 2");
    }
    @include media($mobile) {
      @include calc(width, "100% / 1");
    }
  }
}
