$link-block-margin: 10px;
$link-block-padding: 52px;

.link-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  padding: $link-block-padding;
  white-space: nowrap;

  @include media($mobile) {
    display: none;
  }

  // Top Left
  &.tl {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
  }

  // Top Right
  &.tr {
    top: 0;
    left: auto;
    right: 0;
    bottom: auto;
    text-align: right;
  }

  // Bottom Left
  &.bl {
    top: auto;
    left: 0;
    right: auto;
    bottom: 0;
  }

  // Bottom Right
  &.br {
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
    text-align: right;
  }

  // Group of Items
  &__items {
    width: 100%;
    margin: 0;
    line-height: 18px;
    padding: 0;
    list-style: none;
  }

  &.br,
  &.tr {
    .link-block__items {
      margin-left: $link-block-margin;
    }
  }
  &.bl,
  &.tl {
    .link-block__items {
      margin-left: -$link-block-margin;
    }
  }

  // Single Item
  &__item {
    font-family: 'GT America v1 Compressed', sans-serif;
    font-size: 45px;
    font-weight: bold;
    line-height: 37px;
    position: relative;
    display: inline-block;
    text-decoration: none;
    letter-spacing: 0;
    text-transform: uppercase;
    height: 37px;
    margin: 0 $link-block-margin;

    &.is-menu-nav {
      margin: 0;
    }

    // Focused State
    &.is-focused {
      a {
        color: red;
      }
    }


    .fn_cart-tally {
      color: red;
    }

    a.fn_cart-label.focused {
      color: red;
    }

    // Link
    a {
      color: black;
      text-decoration: none;
      @include transition(all 0.3s ease-in-out);
      &.active {
        color: red;
      }
    }
  }
}