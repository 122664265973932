.footer {
  @include row();
  background-color: black;
  color: white;

  &__top {
    @include row();
    padding-top: 80px;
    padding-bottom: 60px;
  }
  &__bottom {
    @include row();
    padding-bottom: 40px;
  }
  &__left {
    @include span-columns(6);
  }
  &__right {
    @include span-columns(6);
  }
  &__header {
    margin-bottom: 40px;
  }

  #instagram-feed {
    max-width: 350px;
  }

  // Logo
  &__nav {
    @include span-columns(6);
  }

  // Navigation Bar
  &__social {
    @include span-columns(6);
    text-align: right;
  }

}
