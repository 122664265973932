.slide-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  opacity: 0;
  @include transition(all 0.3s ease-in-out);

  &.active { opacity: 1; }

  .swiper-container { width: 100%; height: 100%; }
  .swiper-slide { overflow: hidden; }
  .swiper-pagination-bullet { opacity: 1; background-color: black; }
  .swiper-pagination-bullet-active { opacity: 1; background: red; }
  .swiper-container-vertical > .swiper-pagination-bullets { right: 40px; }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet { width: 6px; height: 6px; margin: 8px 0; }

  &--left {
    left: 0;
    right: auto;
  }
  &--right {
    left: auto;
    right: 0;
  }
  &--mobile {
    width: 100%;
    left: 0;
    right: 0;

    .slide-element {
      &.has-border {
        padding: 120px 40px 40px;
      }
    }
    .swiper-container-vertical > .swiper-pagination-bullets { right: 17px; }
  }
}

.slide-element {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: white;

  &--left {
    &.is-double { width: 200%; left: 0; }
    .slide-button { left: 40px; bottom: 40px; }
  }
  &--right {
    &.is-double { width: 200%; left: -100%; }
    .slide-button { right: 40px; bottom: 40px; }
  }

  &.has-border { padding: 130px; }
}

.slide-item {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  overflow: hidden;

  &--image { }
  &--video {
    video {
      object-fit: cover; width: 100%; height: 100%;
    }
  }
}

.slide-link {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  cursor: pointer;
}

.slide-button {
  position: absolute;
  a {
    @include position(relative);
    display: inline-block;
    margin-right: 10px;
    font-family: $font-bold;
    font-weight: bold;
    font-size: $nav-font-size;
    line-height: $nav-line-size;
    letter-spacing: 0;
    text-transform: uppercase;
    color: black;
    @include pad(0px 0px 0px 0px);
  }
}

.slider-desktop {
  display: block;

  @include media($tablet) { display: none; }
}

.slider-device {
  display: none;

  @include media($tablet) { display: block; }
}
