/* Wrapper */
.sl-wrapper a {
    border-bottom: 0 !important;
    text-decoration: none !important;
}
.sl-button {
    padding: 0.375em 0.625em;
    font-size: 1em;
    line-height: 1;
    font-weight: normal;
}

/* Colors */
a.liked {
    color: #da1b1b;
}
a.liked:hover,
a.liked:active,
a.liked:focus {
    color: #666666;
}
a.liked span.sl-count,
.sl-count {
    color: #666666;
}

/* Icon */
.sl-icon {
    margin-right: 0.3125em;
    font-family: 'Arial Unicode MS', Arial, sans-serif;
    vertical-align: middle;
}
.sl-icon svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
}
.sl-icon svg:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;
}

/* Count */
.sl-count {
    font-size: 0.625em;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    text-transform: uppercase;
    vertical-align: middle;
}

/* Loader */
.loader,
.loader:before,
.loader:after {
  background: rgba(0, 0, 0, 0.2);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: .2em;
  height: .6em;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -.375em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader {
  text-indent: -9999em;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:after {
  left: .375em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 rgba(0, 0, 0, 0.2);
    height: .6em;
  }
  40% {
    box-shadow: 0 -.3em rgba(0, 0, 0, 0.2);
    height: 1em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 rgba(0, 0, 0, 0.2);
    height: .6em;
  }
  40% {
    box-shadow: 0 -.3em rgba(0, 0, 0, 0.2);
    height: 1em;
  }
}