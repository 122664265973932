.ratio {
  @include position(relative);
  @include size(100% auto);
  display: block;

  &__element {
    @include position(absolute, 0 0 0 0);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f5f5f5;
  }  

  // Sizes
  &:after {
    display: block;
    content: '';
  }

  &__title {
    @include position(absolute, 0 0 0 0);
    z-index: 300;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &--banner--sm:after {
    padding-top: 30%;
    @include media($mobile) {
      padding-top: 100%;
    }
  }
  &--banner--md:after {
    padding-top: 40%;
    @include media($mobile) {
      padding-top: 100%;
    }
  }
  &--banner--lg:after {
    padding-top: 50%;
    @include media($mobile) {
      padding-top: 100%;
    }
  }
  &--banner--full:after {
    padding-top: 100vh;
  }
  &--postcard:after {
    padding-top: 66%;
  }
  &--product:after {
    padding-top: 66%;
  }
  &--portrait:after {
    padding-top: 133%;
  }
  &--square:after {
    padding-top: 100%;
  }
}
