$banner-size-full: 100vh;
$banner-size-large: 75%;
$banner-size-medium: 50%;
$banner-size-small: 33.33%;

.banner {
  @include position(relative);
  @include size(100% auto);
  @include align-items(center);
  @include display(flex);
  @include justify-content(center);
  color: white;
  background-color: #000;
  
  .title {
    color: white;
  }

  // Setup the ratio scaling
  // for responsive states
  &:after {
    display: block;
    content: '';
  }

  // Content block
  // for text content
  &__content {
    @include position(relative);
    @include size(100% auto);
    text-align: center;
    z-index: 20;
  }

  // Media block
  // for image and video
  &__media {
    @include position(absolute, 0px);
    background-color: black;
    z-index: 10;
    &__element {
      @include size(100%);
      background-color: transparent;
    }
  }

  // Banner sizes
  &.is-full {
    &:after {
      padding-top: $banner-size-full;
    }
  }
  &.is-large {
    &:after {
      padding-top: $banner-size-large;
    }
  }
  &.is-medium {
    &:after {
      padding-top: $banner-size-medium;
    }
  }
  &.is-small {
    &:after {
      padding-top: $banner-size-small;
    }
  }

  // Image
  &.is-image {
    .banner__media__element {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  // Fade
  &.is-faded {
    .banner__media__element {
      opacity: 0.5;
    }
  }

}