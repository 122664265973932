.zoom {
  cursor: zoom-in;

  &:hover {
    cursor: zoom-in;
  }
  img.zoomImg {
    background-color: #f5f5f5;
  }
}
