.layout {
  padding-top: $header-height;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &__header {
    @include position(fixed, 0px 0px auto 0px);
    @include size(100% $header-height);
    z-index: 3000;
    // line-height: $header-height;
    background-color: transparent;
  }
  &__content {
    flex: 1;
    margin-top: 160px;
    margin-bottom: 160px;
    @include media($mobile) {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }
  &__footer {
    @include position(fixed, auto 0px 0px 0px);
    @include size(100% $footer-height);
    // line-height: $footer-height;
    // background-color: $light-grey;
  }

}

.container {
  @include outer-container();
  @include position(relative);
  padding-left: $page-gutter;
  padding-right: $page-gutter;
  @include media($mobile) {
    padding-left: $mobile-gutter;
    padding-right: $mobile-gutter;
  }
  &.is-full {
    width: 100%;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  &.is-fluid {
    width: 100%;
    max-width: 100%;
    padding-left: $page-gutter;
    padding-right: $page-gutter;
  }
}

.block {
  margin-bottom: 80px;
}

body.single-product {
  .layout__content {
    margin: 0;
  }
}