.wpvoter {
  line-height: 66px !important;
  overflow: hidden;
  width: 100%;
  height: 70px;
  border: 2px solid #000;
  font-size: 32px;
  position: relative;

  &.has-vote {
    color: red;
    border-color: red;
    .wpvoter__email {
      display: none !important;
    }
  }

  &__tally,
  &__action {
    width: 50%;
    text-align: center;
    float: left;
    margin: 0px;
    padding: 0px!important;
    height: 66px;
  }

  &__tally {
    line-height: 66px;
    height: 66px;
    display: block;
  }

  &__button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: black;
    @include transition(all 0.3s ease-in-out);
    span {
      text-transform: uppercase;
    }
    &:hover,
    &:active {
      background-color: red!important;
      color: white!important;
    }
  }

  &__email {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    @include transition(all 0.3s ease-in-out);

    &.show-email {
      left: 0;
    }

    &__input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      text-indent: 20px;
      font-size: 22px;
      position: relative;
      display: block;
    }
    &__submit {
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: black;
      padding: 0 20px;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }

}



// $voter-width: 100%;
// $voter-height: 70px;
// $voter-border-width: 2px;
// $voter-border-color: #000;
// $voter-button-color: #000;
// $voter-color-action: $primary-color;

// // Voted
// $voter-voted-color: $primary-color;
// $voter-voted-border-color: $primary-color;

// .wpvoter {
//   line-height: 64px;
//   display: flex;
// }

// .wpvoter__action {
//   border-left: $voter-border-width solid;
// }

// .wpvoter__button {
//   text-transform: uppercase;
// }

// .wpvoter {
//   font-family: 'GT America v1 Compressed', sans-serif;
//   font-size: 45px;
//   font-weight: bold;
//   line-height: 40px;
//   position: relative;
//   display: inline-block;
//   margin-right: 10px;
//   padding: 0 0 0 0;
//   letter-spacing: 0;
//   text-transform: uppercase;
// }






// .voter {

//   // @include size($voter-width $voter-height);
//   // line-height: $voter-height - $voter-border-width*2;
//   // border: $voter-border-width solid $voter-border-color;
//   // overflow: hidden;

//   .voter__tally,
//   .voter__action {
//     @include size(50% $voter-height - $voter-border-width*2);
//     text-align: center;
//     float: left;
//     margin: 0px;
//     padding: 0px;
//     .title {
//       line-height: $voter-height - $voter-border-width*2;
//     }
//   }

//   .voter__tally {}
//   .voter__action {
//     border-left: $voter-border-width solid;
//   }

//   .voter__button {
//     display: block;
//     border: none;
//     color: inherit;
//     width: 100%;
//     background-color: white;
//     margin: 0!important;
//     padding: 0!important;
//     outline: none;
//     @include on-event {
//       text-decoration: none;
//       color: $voter-color-action;
//     }
//   }

//   &.has-voted {
//     border-color: $voter-voted-border-color;
//     .voter__tally,
//     .voter__action {
//       border-color: $voter-voted-border-color;
//       color: $voter-voted-color;
//     }
//     .voter__button {
//       color: $voter-voted-color;
//     }
//   }

// }
