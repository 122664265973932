//////////////////////////////////////////////////////////////////
// Greyhound Boiler Plate
// Version 1.0.0
// Author: Matthew Woodward
// Website: http://mwdwrd.com/
//////////////////////////////////////////////////////////////////

// URLS
$base-url:                  '/app/themes/prizenyc/assets/' !default;

$font-body:                 'Calibre', sans-serif !default;
$font-light:                'GT America v1 Compressed', sans-serif !default;
$font-medium:               'GT America v1 Compressed', sans-serif !default;
$font-bold:                 'GT America v1 Compressed', sans-serif !default;
$font-icon:                 'Ionicons-Pre' !default;
$font-nav:                  'GT America v1 Compressed', sans-serif !default;

// Typography
$default-font-family:       $font-light;
$default-nav-family:        $font-bold;
$default-icon-family:       $font-icon;
$default-font-size:         16px !default;
$default-font-weight:       200 !default;
$default-line-height:       26px !default;

// Navigation
$nav-font-size:             45px !default;
$nav-line-size:             40px !default;

// Colors
$color-red:                 #e63535 !default;
$color-black:               #000000 !default;
$color-white:               #FFFFFF !default;
$page-background:           #ffffff !default;
$text-color:                $color-black;
$primary-color:             $color-red;
$light-grey:                #f5f5f5 !default;
$link-color:                $primary-color;

// Royalslider
$royalslider-bullet-size:   10px !default;
$royalslider-image-bg:      #f5f5f5 !default;

// Titles
$title-xl-size:             36px !default;
$title-lg-size:             24px !default;
$title-md-size:             18px !default;
$title-sm-size:             14px !default;
$title-xs-size:             10px !default;

// Layout
$header-height:             auto !default;
$footer-height:             60px !default;

// Icons
$icon-size:                 20px !default;

// Gutters
$page-gutter:               40px !default; 
$mobile-gutter:             $page-gutter;

// Product Grid
$product-item-gutter:       40px;
$landscape-ratio:           95%;
$portrait-ratio:            $landscape-ratio*2;

// Breakpoints
$xlarge:                    new-breakpoint(min-width 1200px 12);
$desktop:                   new-breakpoint(max-width 1000px 12);
$tablet:                    new-breakpoint(max-width 768px 12);
$mobile:                    new-breakpoint(max-width 480px 12);

// Colours
$twitter:                     #00aced !default;
$facebook:                    #3b5998 !default;
$googleplus:                  #dd4b39 !default;
$pinterest:                   #cb2027 !default;
$linkedin:                    #007bb6 !default;
$youtube:                     #bb0000 !default;
$vimeo:                       #aad450 !default;
$tumblr:                      #32506d !default;
$instagram:                   #517fa4 !default;
$flickr:                      #ff0084 !default;
$dribbble:                    #ea4c89 !default;
$quora:                       #a82400 !default;
$foursquare:                  #0072b1 !default;
$forrst:                      #5B9A68 !default;
$vk:                          #45668e !default;
$wordpress:                   #21759b !default;
$stumbleupon:                 #EB4823 !default;
$yahoo:                       #7B0099 !default;
$blogger:                     #fb8f3d !default;
$soundcloud:                  #ff3a00 !default;
