.menu-nav {
  position: absolute;
  left: 0;
  top: 100%;
  overflow: hidden;
  max-height: 0;
  @include transition(all 0.3s ease-in-out);
  list-style: none;
  margin: 0;
  padding: 0;

  &.nav-open {
    max-height: 500px;
  }

  .menu-item {
    font-family: 'GT America v1 Compressed', sans-serif;
    font-size: 45px;
    font-weight: bold;
    line-height: 37px;
    position: relative;
    display: block;
    text-decoration: none;
    letter-spacing: 0;
    text-transform: uppercase;
    height: 37px;
    margin: 0;

    a {
      cursor: pointer;
      @include on-event {
        color: $link-color;
        text-decoration: none;
      }
    }
  }
}
