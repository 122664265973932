$nav-dropdown-space: 0px;

.nav {

  .menu-item {
    @include position(relative);
    display: inline-block;
    margin-right: 10px;
    font-family: $font-bold;
    font-weight: bold;
    font-size: $nav-font-size;
    line-height: $nav-line-size;
    letter-spacing: 0;
    text-transform: uppercase;
    @include pad(0px 0px 0px 0px);
    a {
      color: black;
      text-decoration: none; 
      @include on-event {
        color: $link-color;
        text-decoration: none;
      }
    }
    &.active {
      a {
        color: $link-color;
        text-decoration: none;
        @include on-event {
          cursor: default;
          color: $link-color;
          text-decoration: none;
        }
      }
    }
  }
  .dropdown-menu {
    @include transition(max-height 0.2s ease-in-out-cubic);
    @include size(300px auto);
    @include position(absolute, $nav-line-size auto auto 0px);
    @include pad($nav-dropdown-space 0px 0px 0px);
    @include margin(0px);
    max-height: 0px;
    overflow: hidden; 
    .menu-item {
      display: block;
      @include pad(0px 0px $nav-dropdown-space 0px);
      a {
        color: black;
        @include on-event {
          cursor: pointer;
        }
      }
    }
    .current_page_item {
      display: none;
    }
  }

  .menu-item:hover {
    .dropdown-menu {
      max-height: 600px;
      width: 300px;
    }
  }

  &.is-main {
    height: 38px;
  }

  &.is-mobile {
    .menu-item {
      width: 100%;
      display: block;
      font-size: 40px;
    }
  }

  &.is-user {
    height: 38px;
    .menu-item:last-child {
      margin-right: 0px;
    }
  }

  &.is-footer {
    @include pad(0);
    @include margin(0);

    .menu-item {
      color: #777;
      @include position(relative);
      display: inline-block;
      margin-right: 3px;
      font-family: $font-medium;
      font-weight: 500;
      font-size: 12px;
      line-height: auto;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      @include pad(0px 0px 0px 0px);
      &:after {
        content: '/';
        display: inline-block;
        padding-left: 6px;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      a {
        color: #777;
        text-decoration: none; 
        @include on-event {
          color: #777;
          text-decoration: none;
        }
      }
    }
  }
}