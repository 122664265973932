.grid {
    @include row();

    &.align-center {
        display: flex;
        align-items: center;
        @include media($mobile) {
            display: block!important;
        }
    }

    &.is-2 {
        > .grid__item {
            @include span-columns(6);
            @include omega(2n);
            @include media($mobile) {
                @include span-columns(12);
                @include omega(1n);
            }
        }
    }
    &.is-3 {
        > .grid__item {
            @include span-columns(4);
            @include omega(3n);
            @include media($mobile) {
                @include span-columns(12);
                @include omega(1n);
            }
        }
    }
    &.is-4 {
        > .grid__item {
            @include span-columns(3);
            @include omega(4n);
            @include media($mobile) {
                @include span-columns(6);
                @include omega(2n);
            }
        }
    }
    &.is-6 {
        > .grid__item {
            @include span-columns(2);
            @include omega(6n);
            @include media($mobile) {
                @include span-columns(4);
                @include omega(3n);
            }
        }
    }
    .grid__item {
        &.right-padding {
            padding-right: $page-gutter;
        }
        &.left-padding {
            padding-left: $page-gutter;
        }
    }
}
