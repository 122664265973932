.select2-container--default {
  min-width: 160px;
  position: relative;
  top: -2px;
  font-family: $default-font-family;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  outline: none;

  .select2-selection--single {
    border-radius: 0;
    height: 32px;
    border-color: #f5f5f5;
    border-radius: 0;
    background-color: #f5f5f5;
    outline: none;

    .select2-selection__rendered {
      line-height: 30px;
      padding: 0 32px 0 12px;
    }
  }

  .select2-results__option--highlighted,
  .select2-results__option--highlighted[aria-selected] {
    background-color: #f5f5f5;
    color: $color-red;
  }

  .select2-selection__arrow {
    height: 32px !important;
    line-height: 32px !important;
    width: 32px !important;
  }
}

.select2-dropdown.select2-dropdown--below {
  border: none;
  background-color: #f5f5f5;
  border-radius: 0px;
}

.select2-results {
  ul {
    padding-bottom: 0px;

    li {
      font-family: $default-font-family;
      font-size: 15px;
      line-height: inherit;
      letter-spacing: 0.05em;
      font-weight: 500;
      text-transform: uppercase;
      color: #777;
      padding: 0 12px;

      &:last-child {
        padding-bottom: 6px;
      }
    }
  }
}
