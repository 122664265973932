@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

@mixin primary-font() {
  font-family: 'GT America v1 Compressed', sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
}

@mixin secondary-font() {
  font-family: 'GT America v1 Compressed', sans-serif;
  font-weight: 300;
  letter-spacing: 0;
  text-transform: inherit;
}

@mixin block-title() {
  font-family: 'GT America v1 Compressed', sans-serif;
  font-size: 50px;
  line-height: 34px;
  height: 40px;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  display: inline-block;
}