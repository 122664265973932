.wysiwyg {
  font-size: 16px;
  font-weight: 200;
  line-height: 26px;
  font-family: 'Calibre', sans-serif;
  color: #333;

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 200;
    line-height: 26px;
    margin-bottom: 16px;
  }
}
