.social-images {
  margin-bottom: 40px;

  &__inner {
    @include position(relative);
    @include row();
  }

  .social-image {
    @include span-columns(6);
    @include media($mobile) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__instagram {

  }
  &__facebook {

  }
}

.social-image {
  @include position(relative);
  display: block;
  background-color: red;

  &__content {
    @include position(absolute, 0 0 0 0);
    z-index: 500;
    @include display(flex);
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: 'GT America v1 Compressed', sans-serif;
    font-size: 44px;
    font-weight: 600;
    line-height: 54px;
    margin-top: 0;
    letter-spacing: 0;
    text-transform: inherit;
    color: white;
  }

  &__image {
    @include position(absolute, 0 0 0 0);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f5f5f5;
    z-index: 400;
    @include transition(all 0.3s ease-in-out);
  }

  &__link {
    @include position(absolute, 0 0 0 0);
    z-index: 600;
  }

  &:after {
    display: block;
    content: '';
    padding-top: 100%;
  }

  &:hover {
    .social-image__image {
      opacity: 0.3;
    }
  }
}

.address {
  margin-bottom: 40px;
  color: white;

  &__inner {
    @include position(relative);
    @include row();
  }

  &__one,
  &__two {
    @include span-columns(6);
  }
}

.anchor-links {
  margin-top: 40px;
  display: inline-block;
  &__inner {
    margin: 0 -10px;
  }
  @include media($mobile) {
    display: none;
  }
}

.anchor-link {
  display: inline-block;
  margin: 0 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;

  a {
    color: white;
    @include on-event {
      color: $link-color;
      text-decoration: none;
    }
  }
}

.newsletter {
  color: white;
  margin-bottom: 40px;
  @include media($mobile) {
    margin-bottom: 0;
  }

  &__inner {
    @include position(relative);
  }

  &__intro {
    margin-bottom: 40px;
    p {
      color: white;
      font-family: 'Calibre', sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
      margin-top: 0;
      letter-spacing: -.02em;
      text-transform: inherit;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    input {
      width: 100%;
      border: none;
      background: none;
      font-size: 26px;
      padding: 10px 0;
      border-bottom: 2px solid white;
      font-family: $font-body;
      outline: none;
      height: 56px;
      line-height: 56px;
    }
    button {
      color: black;
      border: none;
      background: white;
      white-space: nowrap;
      padding: 0 40px;
      line-height: 56px;
      display: inline-block;
      text-transform: uppercase;
      font-size: 30px;
      font-weight: bold;
      height: 56px;
      @include media($mobile) {
        width: 100%;
        margin-top: 10px;
      }
    }
    @include media($mobile) {
      flex-wrap: wrap;
    }
  }
}

.about-paragraph,
.about-title,
.about-artists,
.about-heading,
.about-intro,
.about-lists {
  margin-bottom: 60px;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__inner {
    padding: 0 40px;
  }
}

.about-intro {

  p {
    font-family: $font-light;
    font-weight: 300;
    font-size: 45px;
    line-height: 48px;
    letter-spacing: -0.02em;
    text-transform: inherit;
    margin-top: 0;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.about-paragraph {
  p {
    font-family: $font-body;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-transform: inherit;
    margin-top: 0;
    margin-bottom: 24px;
  }
}

hr.about-linebreak {
  border: none;
  height: 1px;
  width: 100%;
  background-color: black;
  margin: 0 0 60px 0;
}

.about-title {
  margin-bottom: 20px;
  h4 {
    font-family: $font-light;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.05em;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.about-artists {
  list-style: none;
  margin-left: 0;
  padding: 0;

  &__item {
    font-family: 'GT America v1 Compressed', sans-serif;
    font-size: 45px;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: -.02em;
    text-transform: inherit;
    a {
      color: black;
      @include on-event {
        color: $link-color;
        text-decoration: none;
      }
    }
  }
}

.about-lists {
  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  .about-list {
    width: 50%;
    list-style: none;
    margin: 0 0 40px 0;
    padding: 0;

    &__item {

      &.is-label {
        font-family: 'GT America v1 Compressed', sans-serif;
        font-size: 16px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0;
        letter-spacing: .05em;
        text-transform: uppercase;
        position: relative;
        top: -3px;
      }

      p {
        font-family: $font-body;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-transform: inherit;
        margin-top: 0;
        margin-bottom: 24px;
      }

      a {
        cursor: pointer;
        color: black;
        text-decoration: underline;
        @include on-event {
          color: $link-color;
          text-decoration: none;
        }
      }
    }

    &__title {
      font-family: $font-light;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 0.05em;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}


