.product-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  .swiper-container { width: 100%; height: 100%; }
  .swiper-slide { overflow: hidden; }
  .swiper-pagination-bullet { opacity: 1; background-color: black; }
  .swiper-pagination-bullet-active { opacity: 1; background: red; }
  .swiper-container-vertical > .swiper-pagination-bullets { right: 40px; }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet { width: 6px; height: 6px; margin: 8px 0; }

  .slide-element {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: white;
  }

  .slide-item {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    overflow: hidden;

    &--product { background-color: #f2f2f2; }

    &.fit { background-size: contain; }
    &.fill { background-size: cover; }
  }
}

.mobile-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  .swiper-container { width: 100%; height: 100%; }
  .swiper-slide { overflow: hidden; }
  .swiper-pagination-bullet { opacity: 1; background-color: black; }
  .swiper-pagination-bullet-active { opacity: 1; background: red; }
  .swiper-container-vertical > .swiper-pagination-bullets { right: 40px; }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet { width: 6px; height: 6px; margin: 8px 0; }

  .slide-element {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: white;
  }

  .slide-item {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    overflow: hidden;

    &--product { background-color: #f2f2f2; }

    &.fit { background-size: contain; }
    &.fill { background-size: cover; }
  }
}
