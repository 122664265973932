$product-preview-gutter: 20px;

.product-preview {

  &__inner {
    @include position(relative);
  }

  &__caption {
    @include position(absolute, auto $product-item-gutter/2 20px $product-item-gutter/2);
    height: 40px;
    background-color: #fff;
    line-height: 40px;
    .title {
      line-height: inherit;
      .price {
        color: #999;
        float: right;
      }
    }
    //display: none;
  }

  &__image {
    display: block;
    width: 100%;
    position: relative;

    &__inner {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: $product-preview-gutter;
      bottom: $product-preview-gutter*3;
      right: $product-preview-gutter;
      left: $product-preview-gutter;
      background-color: #f2f2f2;
      @include transition(opacity 0.3s ease-in-out);
    }
    &:hover {
      .is-main {
        opacity: 0;
      }
    }
    &:after {
      padding-top: $landscape-ratio;
      display: block;
      content: '';
    }
    &.is-watch {
      &:after {
        padding-top: $portrait-ratio;
      }
    }
  }

  &.sold-out {
    .product-preview__image {
        opacity: 0.5;
    }
  }
}
