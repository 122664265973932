.image {

  &.is-full {
    @include size(100% auto);
  }
  &.is-circular {
    border-radius: 100%;
    overflow: hidden;
  }
}
