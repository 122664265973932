html,
body {
  color: $text-color;
  font-family: $default-font-family;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: $default-font-weight;
}

p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 200;
  line-height: 26px;
  margin-bottom: 16px;
}

ul, li {
  font-family: $font-body;
}

a {
  color: $link-color;
  text-decoration: none;
  @include transition(color 0.3s ease-in-out);
  @include on-event {
    color: $link-color;
    text-decoration: underline;
  }
}

// Colours
.colour {
  &.is-black {
    color: $color-black;
  }
  &.is-white {
    color: $color-white;
  }
  &.is-red {
    color: $color-red;
  }
}

.is-center {
  text-align: center;
}

// Title Styles
.title {
  font-family: $font-bold;
  font-weight: bold;
  font-size: 50px;
  line-height: 35px;
  letter-spacing: 0;
  text-transform: uppercase;

  &.is-1 {
    font-family: $font-light;
    font-weight: 300;
    font-size: 100px;
    line-height: 100px;
    letter-spacing: 0px;
    text-transform: inherit;
  }
  &.is-2 {
    font-family: $font-bold;
    font-weight: bold;
    font-size: 50px;
    letter-spacing: 0;
  }
  &.is-3 {
    font-family: $font-medium;
    font-weight: 500;
    font-size: 34px;
    text-transform: uppercase;
    line-height: 34px;
    letter-spacing: 2px;
  }
  &.is-4 {
    font-family: $font-medium;
    font-weight: 500;
    font-size: 22px;
    text-transform: uppercase;
    line-height: 22px;
    letter-spacing: 1px;
  }
  &.is-5 {
    font-family: $font-light;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
  }
  &.is-price {
    @include secondary-font();
    font-size: 50px;
    line-height: 70px;
  }
}

.text {
  color: #333;
  font-family: $font-body;

  &.is-center {
    text-align: center;
  }
  &.is-price {
    color: #999;
    margin-left: 7px;
  }
  &.is-credits {
    color: #777;
    font-family: $font-medium;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}
