.page {

    &.is-homepage {
        margin-top: -60px;
    }

    &.is-vote {
        .vote_item {
            margin-bottom: 60px;
            &__voting_box {
                margin-top: 20px;
            }
        }
    }

    &.is-info {
      .page-block.is-left {
        background-color: black;
      }
    }

}


.slick-hp-left {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;

  .img--holder {
    background-position: center center;
    background-size: cover;
  }
}

.slick-vertical .slick-slide,
.img--holder {
  border: none;
  height: 100vh !important;
  width: 100%;
  position: relative;
}

.slick-hp-right {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  .img--holder {
    background-position: center center;
    background-size: cover;
  }
}