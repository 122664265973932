$button-color: $color-black;
$button-height: 55px;
$button-padding: 0 20px;
$button-width: auto;

.button {
    @include size($button-width $button-height)
    @include transition(all 0.3s ease-in-out);
    background-color: $button-color;
    border: 1px solid $button-color;
    color: white;
    display: inline-block;
    font-family: $default-nav-family;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1em;
    line-height: $button-height - 2; // For the Border Offset
    outline: none;
    padding: $button-padding;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &.is-block {
        @include size(100% auto);
    }

    &.is-1 {
        @include span-columns(2);
        float: none;
        display: inline-block;
    }

    &.is-main {
        @extend .title;
        @include size(100% auto);
        padding: 50px 0;
    }

    @include on-event {
        background-color: darken($primary-color,10);
        border-color: darken($primary-color,10);
        color: white; 
        text-decoration: none;
    }
}

.slab-button {
    @include block-title();
}

.slab-price {
    @include block-title();
}