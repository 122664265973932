$carousel-size-full: 100vh;
$carousel-size-large: 700px;
$carousel-size-medium: 500px;
$carousel-size-small: 300px;

.carousel {
  @include size(100%);
  @include position(relative);

  // Banner sizes
  &.is-full {
    height: $carousel-size-full;
  }
  &.is-large {
    height: $carousel-size-large;
  }
  &.is-medium {
    height: $carousel-size-medium;
  }
  &.is-small {
    height: $carousel-size-small;
  }
}