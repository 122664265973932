.pagination {
  font-family: 'GT America v1 Compressed', sans-serif;
  font-size: 45px;
  font-weight: bold;
  line-height: 37px;
  position: relative;
  height: 37px;
  margin: 80px 0 0;
  text-decoration: none;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  display: block;

  span.next,
  span.previous {
    display: none;
  }

  span.page {
    display: inline-block;
    margin: 0 10px;

    &.current {
      color: $color-red;
    }
  }

  a {
    color: #000;
    text-decoration: none;

    &.active,
    &:hover {
      color: $color-red;
      text-decoration: none;
    }
  }
}
