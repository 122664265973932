.cart {
  background-color: #f2f2f2;
  width: 100%;
  overflow-x: auto;
}
.cart__inner {
  max-width: 900px;
  margin: 0 auto;
  padding: 60px 40px;
}
.cart-table {
  width: 100%;
}


.cart-empty {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.cart-empty__inner {
  margin: 0 auto;
  padding: 40px;
  text-align: center;
}
.cart-empty h2 {
  font-family: 'GT America v1 Compressed',sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 45px;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  text-decoration: none;
  letter-spacing: 0;
  text-transform: uppercase;
}



.cart-actions {
  background-color: #ffffff;
}
.cart-actions__inner {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 40px;
}
.cart-actions-table {
  width: 100%;
}
.cart-actions-table__continue {
  text-align: left;
}
.cart-actions-table__checkout {
  text-align: right;
}




.cart-table__header {
  font-family: 'GT America v1 Compressed',sans-serif;
  text-transform: uppercase;
  color: #000;
}

.cart-header tr th {
  padding: 0 0 10px 0;
  border-bottom: 3px solid black;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: .05em;
  text-transform: uppercase;
}

.cart-header tr th:first-child {
  text-align: left;
}

.cart-header tr th:last-child {
  text-align: right;
}




.cart-body tr td {
  padding: 10px 0;
  border-bottom: 3px solid black;
  text-align: left;

}
.cart-body tr td:last-child {
  text-align: right;
}
.cart-body tr td.cart-lineitem__description h2 {
  font-family: 'GT America v1 Compressed',sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0 0 5px 0;
  text-transform: inherit;
}
.cart-body tr td.cart-lineitem__description h2 a {
  color: #000;
  text-decoration: none;
}
.cart-header tr th.cart-header__image,
.cart-body tr td.cart-lineitem__image {
  width: 160px;
}
.cart-header tr th.cart-header__price,
.cart-body tr td.cart-lineitem__price {
  width: 90px;
  padding-left: 10px;
  padding-right: 10px;
}

.cart-header tr th.cart-header__quantity,
.cart-body tr td.cart-lineitem__quantity {
  width: 60px;
  padding-left: 10px;
  padding-right: 10px;
}
.cart-header tr th.cart-header__total,
.cart-body tr td.cart-lineitem__total {
  width: 60px;
  padding-left: 10px;
}
.cart-body tr td.cart-lineitem__quantity .select2-container--default .select2-selection--single {
  background-color: white;
  border-color: white;
}
.cart-body tr td.cart-lineitem__quantity .select2-container--default {
  min-width: 60px;
}



.cart-footer {
  vertical-align: top;
}
.cart-footer tr td {
  padding: 20px 0 0;
}
.cart-footer tr td:last-child {
  text-align: right;
}
.cart-footer tr td.cart-footer__label {
  font-family: 'GT America v1 Compressed',sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0 0 5px 0;
  text-transform: inherit;
  text-align: right;
}
.cart-footer tr td.cart-footer__total {
  padding-left: 20px;
}
.cart-footer tr td.cart-footer__total h3 {
  font-family: 'GT America v1 Compressed',sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0 0 5px 0;
  text-transform: inherit;
}
.cart-notes {
  width: 100%;
  padding: 10px;
  background-color: white;
  border-color: white;
  min-height: 80px;
  outline: none;
}



input.btn-checkout {
  font-family: 'GT America v1 Compressed',sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  text-decoration: none;
  letter-spacing: 0;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  color: #e63535;
}
input.btn-checkout:hover {
  outline: none;
  color: black;
  background-color: white;
  border: none;
}
a.btn-continue,
a.btn-continue:visited {
  font-family: 'GT America v1 Compressed',sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  text-decoration: none;
  letter-spacing: 0;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  color: #999;
}
a.btn-continue:hover,
a.btn-continue:active {
  outline: none;
  color: black;
  background-color: white;
  border: none;
  text-decoration: none;
}
input.btn-update {
  font-family: 'GT America v1 Compressed',sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: .05em;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  color: #e63535;
}
input.btn-update:hover {
  outline: none;
  color: black;
  background-color: white;
  border: none;
}
