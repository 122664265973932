@mixin tab-font() {
  font-family: $font-nav;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.05em;
  margin-top: 0;
  margin-bottom: 0;
}

.tabs {

  &__nav {
    margin: 0;
    padding: 0;
    list-style: none;
    &-item {
      display: inline-block;
      margin: 0 10px 0 0;
      a {
        @include tab-font();
        text-decoration: none;
        color: black;
        opacity: 0.4;
        @include transition(all 0.3s ease-in-out);
      }
      &.active {
        a {
          opacity: 1;
        }
      }
    }
  }
}