$golden:  1.618;
$product-item-width: 1/4;

.product-list {

  &__inner {
    @extend .container.is-large;
  }

  &__items {
    @include position(relative);
    margin: 0 -$product-item-gutter/2;
  }

  &__item {
    @include display(inline-block);
    @include margin(0 0 0 0);
    @include position(relative);
    //padding: 0 $product-item-gutter/2;
    width: 100% * $product-item-width;

    @include media($desktop) {
      width: 100% * $product-item-width;
    }
    @include media($tablet) {
      @include calc(width, "100% / 2");
    }
    @include media($mobile) {
      @include calc(width, "100% / 1");
    }

    &.is-watch {
      width: 100% * $product-item-width;
      // .product-preview__image:after {
      //   padding-bottom: 19px;
      // }

      @include media($desktop) {
        width: 100% * $product-item-width;
      }
      @include media($tablet) {
        @include calc(width, "100% / 2");
      }
      @include media($mobile) {
        @include calc(width, "100% / 1");
      }
    }

    &.is-throw {
      width: 100% * ($product-item-width*2);
      @include media($tablet) {
        @include calc(width, "100% / 1");
      }
      @include media($mobile) {
        @include calc(width, "100% / 1");
      }
    }
  }
}
