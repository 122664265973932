.list {

  &.is-unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &.is-inline {
    .list__item {
      display: inline-block;
    }
  }
}
