.icon {
  font-family: $default-icon-family;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  line-height: $icon-size;
  font-size: $icon-size;

  &--facebook {
    &:before {content: "\f231";}
    @include on-event {
      color: $facebook;
    }
  }
  &--email {
    &:before {content: "\f2eb";}
    @include on-event {
      color: $twitter;
    }
  }
  &--twitter {
    &:before {content: "\f243";}
    @include on-event {
      color: $twitter;
    }
  }
  &--linkedin {
    &:before {content: "\f239";}
    @include on-event {
      color: $linkedin;
    }
  }
  &--youtube {
    &:before {content: "\f24d";}
    @include on-event {
      color: $youtube;
    }
  }
  &--instagram {
    &:before {content: "\f351";}
    @include on-event {
      color: $instagram;
    }
  }

}