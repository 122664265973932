// Width & Height Fix
.royalSlider {
  width: 100%;
  height: 100%;
}

// Z-index fix
.rsSlide {
  z-index: -1;
}

.rsDefaultInv {
  .rsOverflow,
  .rsSlide,
  .rsThumbs,
  .rsVideoFrameHolder {
    background-color: $royalslider-image-bg;
  }
}

// Thumb Styling
.rsDefaultInv {
  .rsTmb {
    padding: 10px;
    background-color: $royalslider-image-bg;
  }
  .rsThumbsHor {
    @include size(100% auto);
    padding: 0!important;
    background-color: $royalslider-image-bg;
  }
}

// Bullet Styling
.rsNav {

  &.rsBullets {
    @include position(absolute, auto 20px 20px 20px);
    @include size(auto auto);
    z-index: 5!important;
    text-align: right;

    .rsBullet {
      @include size($royalslider-bullet-size);
      margin-left: 3px;
      margin-right: 3px;
      display: inline-block;
      padding: 0;
      span {
        @include size($royalslider-bullet-size);
        background-color: transparent;
        border: 1px solid $primary-color;
      }
      &.rsNavSelected {
        span {
          background-color: $primary-color;
        }
      }
    }
  }
}

// Fix Image Links
.grab-cursor {
  cursor: image("grab.png", $base: $base-url) 8 8, move;
}
.grabbing-cursor { 
  cursor: image("grabbing.png", $base: $base-url) 8 8, move;
}
.rsLink {
  background-image: image("blank.gif", $base: $base-url) !important; 
}
.rsDefaultInv {
  .rsArrowIcn,
  .rsThumbsArrowIcn,
  .rsFullscreenIcn,
  .rsPlayBtnIcon,
  .rsCloseVideoIcn { 
    background-image: image("rs-default-inverted.png", $base: $base-url) !important; 
  }
  .rsPreloader { 
    background-image: image("preloader.gif", $base: $base-url) !important; 
  }
}
